import useTranslation from '@/utils/i18n/useTranslation';
import { Center, Icon, Stack, Text } from '@chakra-ui/react';
import { MdWifiOff } from 'react-icons/md';

const NetworkOffline = () => {
  const { t } = useTranslation();
  const topbarHeight = 40;
  const bottomNavHeight = 67;

  return (
    <Center
      h={{
        base: `calc(100vh - ${topbarHeight + bottomNavHeight}px)`,
        md: `calc(100vh - ${topbarHeight}px)`,
      }}
    >
      <Stack align='center'>
        <Icon as={MdWifiOff} boxSize='250' color='primary.500' />
        <Text fontSize='xl'>{t('warning.no-internet-connection')}</Text>
      </Stack>
    </Center>
  );
};

export default NetworkOffline;
