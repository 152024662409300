import { useAddUserClientNotificationTokenMutation } from '@/modules/users/graphql/users.generated';
import { getTokenForPushNotifications, setOnMessage } from '@/plugin/firebase';
import { useToast } from '@/utils/atoms/toast';
import { isSupported } from 'firebase/messaging';
import { useEffect } from 'react';

const useClientNotification = () => {
  const { toast } = useToast();

  const [addUserClientNotificationToken] = useAddUserClientNotificationTokenMutation();

  useEffect(() => {
    isSupported().then(async (isAvailable) => {
      if (!isAvailable) return;
      try {
        const token = await getTokenForPushNotifications();

        addUserClientNotificationToken({
          variables: { token },
        });

        setOnMessage(toast);
      } catch (error) {
        console.warn(error);
      }
    });
  }, [addUserClientNotificationToken, toast]);
};

const RegisterPushToken = () => {
  useClientNotification();

  return null;
};

export default RegisterPushToken;
